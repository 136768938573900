body {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #42484b;
    text-align: left;
    font-weight: 400;
}

a{
    color: #42484b;
}

a:hover {
    color: #42484b;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6{
    font-weight: 600;
    color: #42484b;
}

.shadow{
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 12%);
}

.shadow-sm{
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.dropdown-menu.show{
    box-shadow: rgb(0 0 0 / 24%) 0px 2px 8px 0px;
    border: none;
    max-width: 300px;
    width: 75%;
    border-radius: 13px !important;
}

.dropdown-title{
    font-weight: 600;
}
.dropdown-day{
    font-weight: 600;
}



/* Pagination */
.page-item.active .page-link {    
    background-color: #29a34e;
    border-color: #29a34e;
}

.page-link{
    color: #2c2c2c;
}



/* BSNAV */
.bsnav{
    padding: 10px !important;
}
.navbar-toggler .navbar-toggler-icon, .navbar-toggler .navbar-toggler-icon::after, .navbar-toggler .navbar-toggler-icon::before {
    height: 2px !important;
}
.navbar-toggler .navbar-toggler-icon {
    top: 5px !important;
    width: 83% !important;
}
.navbar-toggler.toggler-spring .navbar-toggler-icon::before {
    top: 6px !important;
}
.navbar-toggler.toggler-spring .navbar-toggler-icon::after {
    top: 13px !important;
}
.navbar-toggler.toggler-spring.active .navbar-toggler-icon::after{
	top: -3px !important;
}
.navbar-toggler.toggler-spring.active .navbar-toggler-icon::before{
	top: -3px !important;
}
#btnnavbar:focus{
	outline: none;
}
/*  */



/* Navbar */
.nav-home{
    color: black !important;
    font-size: 16px;
    font-weight: 600;
}

.navbar-brand{
    width: 110px;
}

.btn-nav-home{
    background-color: #29a34e;
    background-position: center;
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
    border: 1px solid #249d49;
    font-size: 0.86rem;
    letter-spacing: 1px;
    outline: none;
    font-weight: 700;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.btn-nav-home:hover{
    background-color: #20753a;
    border-color: #20753a;
}

.navbar-expand-lg .navbar-nav .nav-link, .navbar-nav .nav-link{
    padding: 8px !important;
}


hr{
	width: 100% !important;
    border-top: 1px solid white !important;
}
.hr-detail{
	width: 100% !important;
    border-top: 1px solid #d3d3d3 !important;
}
.social_list li {
    display: inline-block;
}
.social_list li a {
    display: block;
    width: 30px;
	font-size: 16px;
	color: white
}
.copyright-text-navbar{
	color: white;
    font-size: 12px;
    margin-top: 20px;
}
.copyright-text-navbar a{
	color: #3ced8f;
}


.nav-item .li-menu-dropdown {
    color: #fff;
    list-style-type: initial;
    padding-left: 0;
}

.nav-item .dropdown-menu{
    background: none !important;
    box-shadow: none !important;
}

.nav-item .dropdown-item{
    color: white !important;
    font-size: 12px;
    padding: 0px;
}
/*  */


/* Jumbroton */
.jumbotron{
    background-image: url('../img/other/hero.jpg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 150px 0px 80px 0px;
}

.heading-text{
    font-weight: 700 !important;
    letter-spacing: 1.2px;
    opacity: 0.9;
}

.heading-text{
    letter-spacing: 0.5px;
}
/*  */



/* Resto */
.resto-heading{
    font-size: 1.4rem;
    font-weight: 700;
}

.resto-subheading{
    font-size: 1.1rem;
}

.star-img{
    height: 0.9rem;
}

.box-widget {
	background:#fff;
    border-radius: 10px;
    border: 1px solid #eee;	
	float:left;
	width:100%;
    margin-bottom: 50px;
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 12%);
}

.box-widget.widget-posts li {
	margin-bottom:19px;
	list-style: none;
}

.box-widget.widget-posts .widget-posts-img img{
	 border-radius:4px 4px 0px 0px;
	 width:100%;
     height: 200px;
     object-fit: cover;
}

.box-widget.widget-posts .widget-posts-descr {
    margin: 20px;
}

.box-widget.widget-posts .widget-posts-descr h5{
    line-height: 1.33;    
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
}

.box-widget.widget-posts .widget-posts-descr span{
    line-height: 1.33;    
    font-size: 1rem;
    font-weight: 600;
    vertical-align: bottom;
}

.box-widget-content{
    height: 320px;
}

.box-widget-content ul{
    padding-inline-start: 0px;
}
.open-time{
    margin-top: 20px;
    display: flex;
    margin-bottom: 10px;
}

.open-text{
    font-weight: 700;
    color: black;
    width: 55px;
}
/*  */



/* Footer */
.footer{
    background-color: #f4f4f4;
}

.bottom-footer{
    background-color: rgb(228, 228, 228);    
    align-items: center !important;
    font-size: 14px;
    display: flex;     
    padding: 20px 20px 0px 20px;
}

.cp-left{
    font-weight: 700;
    font-size: 16px;
}

.img-logo-bottom{
    width: 130px;
}

.text-footer-one{
    font-size: 1.2rem;
    color: #000;
    max-width: 360px;
    font-weight: 700;
}
.cp-right{
    float: right;
}
/*  */




/* Breadcrumb */
.bread{
    background-color: #f0f3f5;
    padding: 60px 0px 0px 0px;
}
.breadcrumb{
    background-color: #f0f3f5;
    padding: .35rem 0rem;
}
.breadcrumb-item{
    font-size: 14px;
}
.text-active{
    color: #29a34e !important;
    font-weight: 700;
}


.breadcrumb-item + .breadcrumb-item::before {
    font-family: "FontAwesome";
    content: "\f105 ";
}



/* Detail */
#detail{
    margin-top: 50px;
}

.detail-image{
    position: relative;
    height: 0px;
    padding-top: 83.3333%;
    overflow-y: hidden;
    width: 100%;
    border-radius: 10px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0.01rem 0.3rem;
}

.detail-image img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    object-fit: cover;
}

.title-detail{
    color: black;
    font-weight: 700;
}
.text-detail{
    color: black;
    font-weight: 700;
}

.rating-detail .star-img{
    height: 1.5rem;
}
.rating-detail span{
    font-weight: 600;
    color: black;
    font-size: 1.1rem;
    vertical-align: text-top;
}
.img-detail-content{
    width: 85px;
    align-self: center;
}

.content-card-detail{
    padding: 20px 30px;
    display: flex;
}

.card-left-detail{
    display: flex;
}

.content-second-detail h3{
    color: rgb(37, 40, 43);
    line-height: 1.33;
    font-size: 1.28rem;
}

.carousel-item img{
    border-radius: 10px;
}

.box-detail{
    margin-bottom: 25px;
    border-radius: 7px;
    box-shadow: none;
    border: 1px solid #dee2e6 !important;
    padding: 20px;
}
.box-detail.widget-posts .widget-posts-img {
    float:left;
    width:30%;
}
.box-detail.widget-posts li {
    padding: 1px 0;     
}
.box-widget-content-detail{
    height: 0px;
}
.box-detail.widget-posts .widget-posts-img img{        
    height: auto;
    border-radius: 10px;        
}
.box-detail.widget-posts .widget-posts-descr{
   margin: 5px 20px 0px 100px;
}
.box-detail.widget-posts .widget-posts-descr h5{
   font-size: 14px;
}

.card-content-detail{
    margin-top: 4rem;
}
.ads-card{
    box-shadow: 0 3px 10px 0 rgb(0 0 0 / 12%);
}
/*  */



/* Custom Responsive */
@media screen and (max-width: 950px){
    .nav-home{
        color: white !important;
        font-size: 14px;
    }
    .img-footer{
        display: none;
    }
    .desktop-view{
		display: none;
	}
    .box-widget{
        margin-bottom: 25px;
        border: none;
        box-shadow: none;
    }
    .title-detail{
        margin-top: 20px;
    }
    .box-widget.widget-posts .widget-posts-img {
        float:left;
        width:25%;
    }
    .box-widget.widget-posts li {
        padding: 1px 0;     
    }
    .box-widget-content{
        height: 0px;
    }
    .box-widget.widget-posts .widget-posts-img img{        
        height: auto;
        border-radius: 10px;        
    }
   .box-widget.widget-posts .widget-posts-descr{
       margin: 5px 20px 0px 100px;
   }
   .box-widget.widget-posts .widget-posts-descr h5{
       font-size: 14px;
   }
   .navbar-expand-lg .navbar-nav .nav-link, .navbar-nav .nav-link{
       padding: 8px 0px !important;
   }
   .content-card-detail{
       display: block;
   }
   .resto-heading{
        font-size: 18px;
   }
   .btn-show-content-home{
        font-size: 12px;
    }
    #detail{
        margin-top: 0px;
    }
    .text-detail{        
        font-size: 14px;
    }
    .text-sub-detail{
        font-size: 14px;
    }
    .card-content-detail{
        margin-top: 0rem;
    }
    .ads-card{
        box-shadow: none;
    }
    .content-second-detail h3{
        font-size: 15px;
    }
    .content-second-detail p{
        font-size: 12px;
    }
    .other-location h5{
        font-size: 18px;
    }
    .box-detail{
        border: none !important;
        padding: 0px;
    }
    .txt-dropdown{
        font-size: 14px;
    }
    .pagi-card{
        margin-top: 20px;
    }
}

@media screen and (min-width: 992px){
	.mobile-view{
		display: none;
	}
}

@media screen and (max-width: 773px){
    .heading-text{
        font-size: 1.5rem;
    }
    .resto-subheading {
        font-size: 14px;
    }
    .img-logo-bottom{
        margin-left: 10px;
    }
    .text-footer-one{
        font-size: 16px;
        max-width: 302px;
    }
    .copyright-footer{
        text-align: center;
    }
    .text-footer-two{
        font-size: 15px;
    }
    .cp-right{
        float: none;
    }
}

@media (min-width: 1440px){
    .container {
        padding-left: 60px;
        padding-right: 60px;
        max-width: 1366px;
    }
}